@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600;700&display=swap');
body {
	background-color: #06171E;
  margin: 0;
	font-family: "IBM Plex Sans";
	color: #C7C7C7;
  width: 100%;
  height: 100%;
}
a {
	font-family: "IBM Plex Sans";
  color: #c7c7c7;
  text-decoration: none;
}
p > a {
  font-weight: bold;
  text-decoration: underline;
}








.app {
  display: flex;
  flex-direction: row;
}

main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 100vh;
}
main>div {
  display: flex;
  flex-direction: column;
  width: 100%;
}










header {
  display: flex;
  flex-direction: column;

  background-color: #114255;
  font-family: "IBM Plex Sans";
  font-size: 24pt;
  font-weight: bold;
  text-align: center;

}
.openHeader {
  height: 100vh;
  width: 100%;
  max-width: 300px;
}
.closedHeader {
  justify-content: center;
  height: 100vh;
  min-width: 80px;
  max-width: 80px;
}

.closedHeader:hover {
  color:#f47802;
}
.closedHeader div svg {
  width: 32px;
  margin: 0 auto;
}

.openHeader div {
  text-align:right;
  margin: 12px 24px auto auto;
}
.openHeader div svg {
  height: 32px;
  width: 32px;
}
.openHeader div svg:hover {
  color: #f47802;
  cursor: pointer;
}

.nav-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.nav-container nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 40vh;
}
.nav-container nav:nth-child(2) {
  margin:0 0 10vh 0;
  height: 40vh;
}
.nav-container nav a, .nav-container nav p {
  margin: 3vh 0 0 0;
  text-decoration: none;
}
.nav-container nav a:hover, .nav-container nav p:hover{
  text-decoration: none;
  color: #f47802;
}
.nav-container nav p {
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .closedHeader {
    min-width: 56px;
    max-width: 56px;
  }
  .closedHeader div svg {
    width: 24px;
  }

  .openHeader {
    width: 50vw;
    min-width: 180px;
    max-width: 250px;
    font-size: 24px;
  }
  .openHeader div {
    margin: 16px 16px auto auto;
  }
  .nav-container nav {
    height: 30vh;
  }
  .nav-container nav:nth-child(2) {
    height: 30vh;
    overflow-y:  auto;
  }
}









.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 1220px;
  margin: 15vh 0 15vh 0;
  color: #c7c7c7;
}
.logo-container:hover {
  cursor: pointer;
}
.top-bar svg {
  height: 100%;
}
.top-bar svg:hover {
  color: #F47802;
}
.top-bar .logo-container {
  height: 60px;
  margin: 0 0 0 20px;
}
.top-bar .search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  margin: 0 35px 0 0;
}
.searchInput {
  margin: 0 -40px 0 0;
  padding-left: 16px;
  padding-right: 48px;
  height: 48px;
  width: 240px;
  border-radius: 15px;
  border-width: 0;
  background-color: #114255;
  color: #c7c7c7;
  font-size: 16px
}
.project-container {
  display: flex;
  flex-direction: column;

  width: 1220px;
  margin: 0 0 10vh 0;
}









main article {
	display: flex;
  flex-grow: 1;

	border-radius: 15px;
	background-color: #114255;

  font-size: 16px;

	height: 100px;
  margin: 20px;
}
main article section:first-child {
  flex: 0 0 160px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;

	background-color: #0A2733;
}
main article section:first-child img {
  height: 52px;
  max-width: 150px;
}

main article section:nth-child(2) {
  flex: 1 0 400px;
  min-width: 400px;
}
main article section:nth-child(2) span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0 0 0;
}
main article section:nth-child(2) span h5 {
  margin: 0 16px 0 32px;
	font-size: 24px;
}
.tag-container {
  margin: 0 4px;
  background-color: #f47802;
  color: #000000;
  font-weight: bold;
  border-radius: 16px;
}
.tag-container p {
  margin: 4px 16px;
}
main article section:nth-child(2) > p {
	margin: 16px 64px 16px 32px;
	font-size: 16px;

  overflow-y: hidden;
}

main article section:last-child {
  flex: 0 0 440px;
  border-left: solid 1px #06171E;
}
.git-container {
	display: flex;
	flex-direction: row;
  align-items: center;
}
.git-container svg {
	margin: 0 40px 0 20px;
  height: 32px;
  width: auto;
}
.git-container a {
	display: flex;
	margin: 0 20px 0 0;
	text-align: center;
	text-decoration: none;
	color: #F47802;
	font-weight: 600;
}
@media screen and (max-width: 850px) {
  article {
    color: #c7c7c7;
  }
}















.h-col-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 50%;
}
.v-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.disabled {
  display: none;
}



.scroll-y {
  overflow-y: auto;
}
.header-scrollbar {
  scrollbar-color: #185C77 #114255;
}
.main-scrollbar {
  scrollbar-color: #0A2733 #06171E ;
}
.none-scrollbar {
  scrollbar-color: rgb(0, 0, 0, 0) rgb(0, 0, 0, 0);
}
main ::-webkit-scrollbar {
  background-color: #06171E;
}
main ::-webkit-scrollbar-thumb {
  background-color: #0A2733
}
header ::-webkit-scrollbar {
  background-color: #114255;
}
header ::-webkit-scrollbar-thumb {
  background-color: #185C77;
}